<template>
  <b-modal
    id="select_orders_modal"
    ref="selectorders"
    size="lg"
    modal-class="modal-primary select-orders"
    centered
    hide-header-close
    hide-header
    hide-footer
  >
    <div>
      <div class="pb-50 border-bottom">
        <span
          class="custom-modal-heading color-dark-black"
        >Updating Orders
        </span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="closeOrderModal"
        />
      </div>
      <b-row>
        <b-col class="col-12">
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              type="text"
              class="form-control-merge"
              placeholder="Search order id, retailer name..."
              @input="onSearchOrders"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                size="18"
                class="cursor-pointer"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="orders-modal-row-header">
        <b-col class="col-6">
          Order Id
        </b-col>
        <b-col class="col-6">
          Retailer Name
        </b-col>
      </b-row>
      <div class="orders-block scroll-bar">
        <b-form-checkbox-group
          v-model="selectedOrders"
          class="orders-checkbox"
        >
          <b-form-checkbox
            v-for="order in orderList"
            :key="order.orderId"
            :value="order.orderId"
          >
            <b-row class="w-100  cursor-pointer">
              <b-col class="col-6">
                {{ order.orderSeqId }}
              </b-col>
              <b-col class="col-6">
                {{ order.retailerName }}
              </b-col>
            </b-row>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </div>
    <div>
      <div class="text-center">
        <b-button
          type="reset"
          class="mt-2 mr-2 px-sm-3 font-weight-bolder"
          variant="outline-info"
          @click="closeOrderModal"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="info"
          class="mt-2 px-sm-3 font-weight-bolder"
          :disabled="isSaving"
          @click="updateOrders(selectedOrders)"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
BButton, BCol, BFormCheckbox, BFormCheckboxGroup, BFormInput, BInputGroup, BInputGroupAppend, BModal, BRow, VBModal,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BModal,
        BFormCheckbox,
        BFormCheckboxGroup,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        FeatherIcon,
    },
    directives: {
        'v-b-modal': VBModal,
    },
    props: {
        orders: {
          type: Array,
          default: () => [],
        },
        updateOrders: {
            type: Function,
            default: () => {},
        },
        isSaving: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
            orderList: [],
            selectedOrders: [],
        }
    },
    watch: {
      orders(val) {
        this.orderList = val
      },
    },
    methods: {
        closeOrderModal() {
            this.$bvModal.hide('select_orders_modal')
        },
        onSearchOrders(searchString = '') {
  const searchRegex = new RegExp(searchString, 'i')
  this.orderList = this.orders.filter(order => searchRegex.test(order?.orderSeqId) || searchRegex.test(order?.retailerName))
},
    },

}
</script>
