<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.49498 0.299968C6.22161 0.0266003 5.77839 0.0266003 5.50502 0.299968L2.50503 3.29997L2.3636 3.44139C2.16834 3.63665 2.16834 3.95323 2.3636 4.1485L2.64645 4.43134C2.84171 4.6266 3.15829 4.6266 3.35355 4.43134L3.49497 4.28992L5.3 2.48489V8.29494V8.49494C5.3 8.77108 5.52386 8.99494 5.8 8.99494H6.2C6.47614 8.99494 6.7 8.77108 6.7 8.49494V8.29494V2.48489L8.50503 4.28992L8.64645 4.43134C8.84171 4.6266 9.15829 4.6266 9.35355 4.43134L9.6364 4.1485C9.83166 3.95323 9.83166 3.63665 9.6364 3.44139L9.49497 3.29997L6.49498 0.299968ZM11 12.195C11.2761 12.195 11.5 11.9711 11.5 11.695V11.295C11.5 11.0188 11.2761 10.795 11 10.795H1C0.723858 10.795 0.5 11.0188 0.5 11.295V11.695C0.5 11.9711 0.723858 12.195 1 12.195H11Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'MassUploadIcon',
}
</script>
