<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.62912 0.090384C7.54508 0.0315548 7.44497 0 7.34239 0H4.65761C4.55503 0 4.45492 0.0315548 4.37088 0.0903841L3.20055 0.909616C3.11651 0.968445 3.0164 1 2.91382 1H0.5C0.223858 1 0 1.22386 0 1.5V1.9C0 2.17614 0.223858 2.4 0.5 2.4H11.5C11.7761 2.4 12 2.17614 12 1.9V1.5C12 1.22386 11.7761 1 11.5 1H9.08618C8.9836 1 8.88349 0.968445 8.79945 0.909616L7.62912 0.090384Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4412 4.55289C10.4726 4.25749 10.2411 4 9.94399 4H2.05601C1.75894 4 1.52739 4.25749 1.55882 4.55289L2.45244 12.9529C2.47948 13.2071 2.69396 13.4 2.94963 13.4H9.05037C9.30604 13.4 9.52052 13.2071 9.54756 12.9529L10.4412 4.55289ZM8.91964 5.62116C8.93221 5.503 8.83959 5.4 8.72076 5.4H3.27924C3.16041 5.4 3.06779 5.503 3.08036 5.62116L3.73994 11.8212C3.75076 11.9229 3.83655 12 3.93882 12H8.06118C8.16345 12 8.24924 11.9229 8.26006 11.8212L8.91964 5.62116Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'CollectionFileDeleteIcon',
}
</script>
